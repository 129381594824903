import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import RecipeCard from "../components/recipe"
import AdSense from 'react-adsense';
import { Box } from "@mui/material"

type DataProps = {
    site: {
        buildTime: string
    }
    cps: {
        allRecipes: any[],
        searchItems: any[]
    }
}

export const query = graphql`

    query Load {
        cps {
            searchItems {
                ... on CPS_Recipe {
                    id
                    name
                    searchItemType
                  }
                  ... on CPS_Ingredient {
                    id
                    name
                    searchItemType
                    ingredientType
                  }
            }
            allRecipes {
                id
                name
                items {
                    quantity
                    ingredientId
                    link
                    ingredient {
                        name
                        parent
                        masterLink
                    }
                }
                preparation
                link
                iceType {
                    id
                    name
                    icon
                    link
                }
                glassType {
                    id
                    name
                    icon
                    link
                }
            }
        }
    }

`


const Index: React.FC<PageProps<DataProps>> = ({ data, path }) => {

    const [searchItems] = React.useState(data.cps.searchItems);
    const [selectedSearchItems, setSelectedSearchItems] = React.useState([]);
    const [recipes] = React.useState(data.cps.allRecipes);
    const [selectedRecipes, setSelectedRecipes] = React.useState([]);
    const [featuredRecipes, setFeaturedRecipes] = React.useState([]);

    const handleChange = (value) => {
        setSelectedSearchItems(value);
    }

    React.useEffect(() => {
        // Get the filtered recipes

        const recipesToShow = recipes.filter((recipe) => {

            if (selectedSearchItems.findIndex((item) => {
                if (item.searchItemType === 1 && item.id === recipe.id) {
                    return true;
                }

                return false;
            }) > -1) {
                return recipe;
            }

            return recipe.items.findIndex(item => {
                return selectedSearchItems
                    .findIndex(i =>
                        i.id === item.ingredientId &&
                        i.searchItemType === 0) > -1
            }) > -1
        });


        setSelectedRecipes(recipesToShow);

    }, [selectedSearchItems]);

    React.useEffect(() => {
        const featured = [];
        for (var i = 0; i < 3; i++) {
            featured.push(recipes[GetRandomInt(recipes.length)]);
        }
        setFeaturedRecipes(featured);
    }, [])

    const GetRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    }


    return (
        <Layout>
            <div className="flex flex-col items-center">
                <Seo title="Cocktail Pantry Search" />
                <h1 className="md:text-center text-blue font-bold text-3xl px-4">Type the ingredients you have and we'll show you what to make</h1>
                <div className="flex w-full mt-2 md:w-3/4 mx-16 search-bar items-center justify-center">
                    <StaticImage className="ml-4" alt="Cocktail Search" src="../images/loupe.png" width={22} height={22}></StaticImage>
                    {/* <div className="pointer-events-auto ...">
                        <svg className="text-slate-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                    </div> */}
                    <Autocomplete
                        multiple
                        className="search-bar w-full ml-2 mr-6"
                        id="search-field"
                        options={searchItems}
                        filterSelectedOptions
                        renderOption={(props: object, option: any, state: object) => (
                            <Box component="li" {...props} className="MuiAutocomplete-option">
                                {option.name} 
                                <div className={`ml-2 text-xs text-white px-2 rounded-full ${option.searchItemType === 1 ? ' bg-blue-800 ' : ' bg-indigo-600 '}`}>
                                    {
                                        option.searchItemType === 1 ? 'Recipe' : 

                                        (
                                            option.ingredientType === 0 ? 'Spirit' : 
                                            (
                                                option.ingredientType === 2 ? 'Garnish' : ''
                                            )
                                        )
                                    }
                                </div>
                            </Box>
                        )}
                        getOptionLabel={(ingredient) => ingredient.name}
                        onChange={(e, v) => handleChange(v)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="ex. Gin, Lemon, etc."
                            />
                        )}
                    ></Autocomplete>
                </div>
                {/* <div className="mt-4 content-center">
                    <ins className="adsbygoogle"
                        style={{display: "block"}}
                        data-ad-format="auto"
                        data-ad-client="ca-pub-5698612954230837"
                        data-ad-slot="2544512876"
                        data-full-width-responsive="true"></ins>
                </div> */}
                {/* <AdSense.Google
                    client='ca-pub-5698612954230837'
                    slot='2544512876'
                    format='auto'

                /> */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
                    {selectedRecipes && selectedRecipes.length > 0 &&

                        selectedRecipes.map((recipe) => {

                            return (
                                <RecipeCard key={recipe.id} recipe={recipe} />
                            )
                        })
                    }
                </div>
                <div className="flex flex-col directions-div mt-20 w-100 text-center py-20 items-center">
                    <div className="flex flex-col md:w-3/4 lg:w-1/3 px-6 text-center justify-center items-center">
                        <h2 className="text-4xl font-bold">
                            How to Search Drinks by Ingredients?
                        </h2>
                        <p className="mt-10 text-2xl font-semibold text-left">
                            Cocktail Pantry Search is a way to intuitively <span className="highlight-yellow">search</span> for cocktail recipes <span className="highlight-yellow">based on the ingredients you have at hand.</span>
                        </p>
                        <p className="mt-10 text-2xl font-semibold text-left">
                            Type the ingredients you  have available in your pantry or bar cart, and <span className="highlight-yellow">we show you cocktail recipes you can make.</span>
                        </p>
                        <p className="mt-10 text-4xl font-semibold italic">
                            You don't have to chase a recipe, the recipe comes to you
                        </p>
                    </div>
                </div>
                <h2 className="mt-20 text-2xl font-bold">
                    Featured Recipes
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                    {
                        featuredRecipes &&
                        featuredRecipes.map((recipe) => {
                            return (
                                <RecipeCard key={"featured" + recipe.id} recipe={recipe} />
                            )
                        })
                    }
                </div>
            </div >
        </Layout >
    )
}

export default Index;
