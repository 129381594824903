
import { getImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import "../styles/recipe.css"
import { InfoOutlined } from "@mui/icons-material";

type Recipe = {
    name: string;
    items: any[],
    preparation: string;
    glassType: any;
    iceType: any;
}

const RecipeCard = (prop) => {
    const [recipe] = React.useState<Recipe>(prop.recipe);

    const Items = (i) => {
        const items = i.filter((item) => item.ingredient.ingredientType !== 2);
        return items.sort((i1, i2) => i1.ingredient.ingredientType - i2.ingredient.ingredientType)
    }

    const Garnishes = (items) => {
        return items.filter((item) => {
            return item.ingredient.ingredientType === 2
        })
    }

    const HasGarnishes = (items) => {
        return items.findIndex(item => item.ingredient.ingrendientType === 2) > -1;
    }

    const goToRecipeLink = () => {

        var link = `https://www.cocktailpantrysearch.com/recipe/${recipe.name.split(" ").join("-")}`
        window.open(link, "_blank");
    }

    return (
        <div className="card flex flex-col p-4 transition delay-50 duration-200 ease-in-out hover:scale-110">
            <h2 className="uppercase text-center recipe-name hover:cursor-pointer transition delay-50 duration-200 ease-in-out hover:scale-110" onClick={() => goToRecipeLink()}>
                {recipe.name}
            </h2>
            <span className="text-center ellipsis">...</span>
            <span className="category">
                Ingredients
            </span>
            <RecipeIngredientList items={Items(recipe.items)} />
            {
                HasGarnishes(recipe.items) &&
                <>
                    <span className="category">
                        Garnishes
                    </span>
                    <RecipeIngredientList items={Garnishes(recipe.items)} />
                </>
            }
            <span className="mt-2 category">
                Preparation
            </span>
            <p className="mt-2 preparation">
                {recipe.preparation}
            </p>

            <div className="flex justify-evenly items-center">
                {
                    (recipe.glassType && recipe.glassType.icon) &&
                    <GlassImage icon={recipe.glassType.icon} name={recipe.glassType.name} />
                }
                {
                    (recipe.iceType && recipe.iceType.icon) &&
                    <IceImage icon={recipe.iceType.icon} name={recipe.iceType.name} />
                }
            </div>
        </div>
    )
};
export default RecipeCard;


const RecipeIngredientList = (prop) => {

    const [items] = React.useState(prop.items);
    return (
        <div className="flex flex-col pl-4">
            {
                items &&
                items.map((item) => {
                    return (
                        <RecipeIngredientItem key={item.ingredientId} item={item}></RecipeIngredientItem>
                    )
                })
            }
        </div>
    )
}

export const RecipeIngredientItem = (prop) => {
    const [item] = React.useState(prop.item);

    const goToLink = (link) => {
        window.open(link, "_blank");
    }

    return (
        <div className="flex w-full justify-between py-1">
            <span className="flex flex-1">

                {
                    item.quantity &&
                    (
                        <span className="quantity">{item.quantity}</span>
                    )
                }
                <span onClick={() => goToLink(item.link ? item.link : item.ingredient.masterLink)} className={(item.link || item.ingredient.masterLink ? "item-link  transition delay-50 duration-200 ease-in-out hover:scale-110 " : "") + (item.quantity ? "pl-2" : "")}>
                    {item.ingredient.name}
                </span>
                {
                    (item.link || item.ingredient.masterLink) &&
                    <InfoOutlined fontSize="small" className="ml-1" />
                }
            </span>
        </div>
    )
}


export const GlassImage = ({ icon, name }) => {

    return (
        <div className="flex flex-col items-center">
            {
                icon === "collins.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/collins.png"></StaticImage>
            }
            {
                icon === "cobbler.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/cobbler.png"></StaticImage>

            }
            {
                icon === "coupe.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/coupe.png"></StaticImage>

            }
            {
                icon === "flute.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/flute.png"></StaticImage>

            }
            {
                icon === "hurricane.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/hurricane.png"></StaticImage>

            }
            {
                icon === "rocks.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/rocks.png"></StaticImage>

            }
            {
                icon === "tiki.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/tiki.png"></StaticImage>

            }
            {
                icon === "wine.png" &&
                <StaticImage width={55} height={55} alt="glassware" src="../images/glassware/wine.png"></StaticImage>

            }
            <span className="font-semibold">{
                name
            }
            </span>

        </div>
    )
}

export const IceImage = ({ icon, name }) => {
    return (
        <div className="flex flex-col items-center">
            {
                icon === "crushed-ice.png" &&
                <StaticImage width={55} height={55} alt="ice" src="../images/ice/crushed-ice.png"></StaticImage>
            }
            {
                icon === "ice-cubes.png" &&
                <StaticImage width={55} height={55} alt="ice" src="../images/ice/ice-cubes.png"></StaticImage>

            }
            {
                icon === "ice-rock.png" &&
                <StaticImage width={55} height={55} alt="ice" src="../images/ice/ice-rock.png"></StaticImage>

            }
            <span className="font-semibold">
                {
                    name
                }
            </span>

        </div>
    )
}